import {AfterViewInit, Component, inject, OnInit} from '@angular/core';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DocType, Gender, ItemMode, ListDocType, patchFormValue, REGEX} from '../../shared/shared.service';
import moment from 'moment';
import {IdReadInfo, IdReadInfoType} from '../../shared/models/idRead.model';
import {IdentificationReaderService} from '../../shared/service/identificationReader.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {TranslateService} from '@ngx-translate/core';
import {Subscription, take} from 'rxjs';

@Component({
  selector: 'app-sharer-info',
  templateUrl: './sharer-info.component.html',
  styleUrl: './sharer-info.component.scss'
})
export class SharerInfoComponent implements OnInit, AfterViewInit {
  readonly nzModalData = inject(NZ_MODAL_DATA);
  data: any;
  formGroup!: FormGroup;
  itemMode = ItemMode;
  mode = ItemMode.Add;
  listDocType: any[] = ListDocType;
  listGender = [
    {text: 'gender.male', value: 'M'},
    {text: 'gender.female', value: 'F'},
  ];
  REGEX = REGEX;
  currentDate = moment().add(1, 'days').set({h: 0, m: 0, s: 0, ms: -1}).toDate();
  scanSubscription!: Subscription;

  constructor(
    private fb: FormBuilder,
    private modalRef: NzModalRef,
    private identificationReaderService: IdentificationReaderService,
    private noti: NzNotificationService,
    private translateService: TranslateService
  ) {
    if (this.nzModalData.data) {
      this.data = JSON.parse(JSON.stringify(this.nzModalData.data));
    }
    this.mode = this.nzModalData.mode;
  }

  get f() {
    return this.formGroup.controls;
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      id: [undefined],
      full_name: [null, [Validators.required, Validators.maxLength(255)]],
      phone: [null, [Validators.pattern(REGEX.phone), Validators.maxLength(12)]],
      gender: [null, Validators.required],
      birthday: [null, Validators.required],
      doc_type: [null, Validators.required],
      doc_no: [null, Validators.required],
      issue_date: [null, Validators.required],
      issue_place: [null, Validators.required],
      expired_date: [null, Validators.required],
      address: [null, Validators.required],
      doc_img_front: [null],
      doc_img_back: [null]
    })
    this.getIdInfo();
  }

  ngAfterViewInit() {
    if (this.mode !== ItemMode.Add) {
      patchFormValue(this.formGroup, this.data);
      this.f['birthday'].setValue(moment(this.data.birthday).toDate());
      this.f['issue_date'].setValue(moment(this.data.issue_date).toDate());
      this.f['expired_date'].setValue(moment(this.data.expired_date).toDate());
    }
  }

  editMode() {
    this.mode = ItemMode.Edit;
    patchFormValue(this.formGroup, this.nzModalData.data);
    this.f['birthday'].setValue(moment(this.data.birthday).toDate());
    this.f['issue_date'].setValue(moment(this.data.issue_date).toDate());
    this.f['expired_date'].setValue(moment(this.data.expired_date).toDate());
    this.formGroup.enable({emitEvent: false});
  }

  destroyModal() {
    this.scanSubscription.unsubscribe()
    this.modalRef.destroy()
  }

  handleFileUpload(key: string, url: string) {
    this.f[key].setValue(url);
  }

  onSave(next = false) {
    if (this.formGroup.valid) {
      this.scanSubscription.unsubscribe();
      if (next) {
        this.modalRef.destroy({continue: next, data: this.formGroup.getRawValue()});
      } else {
        this.modalRef.destroy({data: this.formGroup.getRawValue()});
      }
    } else {
      Object.values(this.formGroup.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  getIdInfo() {
    this.scanSubscription = this.identificationReaderService.getIdReadInfo().subscribe((idReadInfo: IdReadInfo) => {
      if (idReadInfo.id === IdReadInfoType.ID_INFO) {
        if (this.nzModalData.listIdentityCode.indexOf(idReadInfo.data.idCode) === -1) {
          const textSplit = idReadInfo.data.qr.split('|');
          this.f['guest_name'].setValue(idReadInfo.data.personName || textSplit[3]);
          this.f['gender'].setValue((idReadInfo.data.gender || textSplit[4]) === 'Nam' ? Gender.Male : Gender.FeMale);
          this.f['birthday'].setValue(idReadInfo.data.dateOfBirth ?
            moment(idReadInfo.data.dateOfBirth, 'DD/MM/YYYY').utc(false).toDate() : moment(textSplit[3], 'DDMMYYYY').utc(false).toDate());
          this.f['doc_type'].setValue(DocType.Identification);
          this.f['doc_no'].setValue(idReadInfo.data.idCode || textSplit[0]);
          this.f['issue_date'].setValue(idReadInfo.data.issueDate ?
            moment(idReadInfo.data.issueDate, 'DD/MM/YYYY').utc(false).toDate() : moment(textSplit[6], 'DDMMYYYY').utc(false).toDate());
          this.f['expired_date'].setValue(moment(idReadInfo.data.expiryDate, 'DD/MM/YYYY').utc(false).toDate());
          this.f['address'].setValue(idReadInfo.data.residencePlace || textSplit[5]);
          this.f['issue_place'].setValue(this.translateService.instant('doc.default-place'));
          this.noti.success('', this.translateService.instant('booking.identify-read-success'));
        } else {
          this.noti.error('', this.translateService.instant('booking.identify-exist'));
        }
      }
    });
  }
}
