import {Component, inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProxyDynamicService} from '../../shared/service/proxy-dynamic.service';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';
import {BookingStatus} from '../../shared/shared.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-refund-modal',
  templateUrl: './refund-modal.component.html',
  styleUrl: './refund-modal.component.scss'
})
export class RefundModalComponent {
  formGroup!: FormGroup;
  readonly nzModalData = inject(NZ_MODAL_DATA);

  constructor(
    private formBuilder: FormBuilder,
    private service: ProxyDynamicService,
    private modalRef: NzModalRef,
    private noti: NzNotificationService,
    private translate: TranslateService,
  ) {
    this.formGroup = this.formBuilder.group({
      night_count: [null, Validators.required],
      note: [null]
    })
  }

  destroyModal() {
    this.modalRef.destroy();
  }

  submit() {
    let status = '';
    if (this.nzModalData.data.status === BookingStatus.InHouse) {
      status = BookingStatus.BackEarly
    }
    if (this.nzModalData.data.status === BookingStatus.Booked) {
      status = BookingStatus.Canceled
    }
    if (this.formGroup.valid) {
      const obj = {
        night_count: this.formGroup.controls['night_count'].value,
        note: this.formGroup.controls['note'].value,
        apartment_id: this.nzModalData.data.apartment.id,
        booking_id: this.nzModalData.data.id,
        status: status
      }
      this.service.createItem('booking/night-refund', obj).subscribe(() => {
        switch (this.nzModalData.data.status) {
          case BookingStatus.Booked:
            this.noti.create('success', this.translate.instant('cancelSuccess'), '');
            break;
          case BookingStatus.InHouse:
            this.noti.create('success', this.translate.instant('refundSuccess'), '');
            break
        }
        this.modalRef.destroy(true);
      })
    } else {
      Object.values(this.formGroup.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }
}
