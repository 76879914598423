/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {Injectable} from "@angular/core";
import {WebSocketService} from "./websocket.service";
import {Subject} from "rxjs";
import {IdReadInfo} from "../models/idRead.model";
import {SocketIoService} from './socket-io.service';

const READER_SOCKET_URL = "wss://192.168.5.1:8000";


@Injectable()
export class IdentificationReaderService {
  private idReadSubject: Subject<IdReadInfo> = new Subject<IdReadInfo>();
  private idReaderConnectedSubject: Subject<boolean> = new Subject<boolean>();

  constructor(private socketService: SocketIoService) {
  }

  initialize() {
    this.socketService.createWebSocket(READER_SOCKET_URL);
    this.subcribeConnectedState();
    this.subcribeMessages();
  }

  private subcribeConnectedState() {
    return this.socketService.getConnectState().subscribe((connected: boolean) => {
      this.idReaderConnectedSubject.next(connected);
    });
  }

  // private subcribeMessages() {
  //   return this.socketService.getMessages().subscribe((message: any) => {
  //     console.log('ABC', message);
  //     // if (message === "Đã kết nối!") return;
  //     const idInfo = message as IdReadInfo;
  //     this.idReadSubject.next(idInfo);
  //   });
  // }
  private subcribeMessages() {
    return this.socketService.getMessage('/event').subscribe((data: any) => {
      this.idReadSubject.next(data as IdReadInfo);
    });
  }

  getIdReadInfo() {
    return this.idReadSubject.asObservable();
  }

  getIdReaderConnectedState() {
    return this.idReaderConnectedSubject.asObservable();
  }
}
