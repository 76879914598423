import {AfterViewInit, ChangeDetectorRef, Component, inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProxyDynamicService} from '../../shared/service/proxy-dynamic.service';
import {
  BookingStatus, CardStatus,
  DeleteStatus, DocType,
  Gender,
  ItemMode,
  ListDocType, MasterDataStatus,
  patchFormValue,
  REGEX
} from '../../shared/shared.service';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import moment, {duration} from 'moment/moment';
import {distinctUntilChanged, Subscription} from 'rxjs';
import {SharerInfoComponent} from '../sharer-info/sharer-info.component';
import {environment} from '../../../environments/environment.prod';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {TranslateService} from '@ngx-translate/core';
import {IdReadInfo, IdReadInfoType} from '../../shared/models/idRead.model';
import {IdentificationReaderService} from '../../shared/service/identificationReader.service';

@Component({
  selector: 'app-booking-item',
  templateUrl: './booking-item.component.html',
  styleUrl: './booking-item.component.scss'
})
export class BookingItemComponent implements OnInit, AfterViewInit {
  readonly nzModalData = inject(NZ_MODAL_DATA);
  formGroup!: FormGroup;
  itemMode = ItemMode;
  mode = ItemMode.Add;
  data!: any;
  listBuilding: any[] = [];
  listApartment: any[] = [];
  listStatus: any[] = [];
  listDocType: any[] = ListDocType;
  listGender = [
    {text: 'gender.male', value: Gender.Male},
    {text: 'gender.female', value: Gender.FeMale},
  ];
  REGEX = REGEX;
  currentDateMax = moment().add(1, 'd').set({h: 0, m: 0, s: 0, ms: -1}).toDate();
  currentDateMin = moment().set({h: 0, m: 0, s: 0, ms: 0}).toDate();
  expand = true;
  listDataShareGuest: any[] = [];
  moment = moment;
  scanMainGuest = true;
  scanSubscription!: Subscription;
  keywordApartment = '';
  cancelSearchApartment = false;

  constructor(
    private service: ProxyDynamicService,
    private modalRef: NzModalRef,
    private modalService: NzModalService,
    private noti: NzNotificationService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private identificationReaderService: IdentificationReaderService,
    private cdr: ChangeDetectorRef,
  ) {
    this.listStatus = JSON.parse(JSON.stringify(this.nzModalData.listStatus));
    this.listBuilding = JSON.parse(JSON.stringify(this.nzModalData.listBuilding));
    this.mode = this.nzModalData.mode;
    if (this.nzModalData.data) {
      this.data = JSON.parse(JSON.stringify(this.nzModalData.data));
    }
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      id: [undefined],
      booking_date: [this.currentDateMin, Validators.required],
      building: [null, Validators.required],
      apartment: [null, Validators.required],
      owner: [null],
      ownerName: [null],
      owner_remaining_nights: [null],
      guest_remaining_nights: [null],
      adults_count: [1, [Validators.required, Validators.min(1)]],
      child_count: [0],
      from_date: [moment().set({h: 12, m: 0, s: 0, ms: 0}).toISOString(true)],
      fromTo: [[moment().set({h: 12, m: 0, s: 0, ms: 0}).toDate(), moment().add(1, 'days').set({h: 11, m: 59, s: 0, ms: 0}).toDate()], Validators.required],
      to_date: [moment().add(1, 'days').set({h: 11, m: 59, s: 0, ms: 0}).toISOString(true)],
      night_count: [1],
      guest_name: [null, [Validators.required, Validators.maxLength(255)]],
      guest_phone: [null, [Validators.pattern(REGEX.phone), Validators.maxLength(12)]],
      gender: [null, Validators.required],
      birthday: [null, Validators.required],
      doc_type: [null, Validators.required],
      doc_no: [null, Validators.required],
      issue_date: [null, Validators.required],
      issue_place: [null, Validators.required],
      expired_date: [null, Validators.required],
      address: [null, Validators.required],
      doc_img_front: [null],
      doc_img_back: [null],
      guest_sharing: [null],
      source: ['CMS'],
    })
    this.f['ownerName'].disable();
    this.f['owner_remaining_nights'].disable();
    this.f['guest_remaining_nights'].disable();
    this.f['night_count'].disable();
    this.f['apartment'].valueChanges.pipe(distinctUntilChanged()).subscribe((value: any) => {
      const obj = this.listApartment.find(r => r.id === value);
      if (obj) {
        this.f['adults_count'].setValidators([Validators.max(obj.apartment_type.max_adults)]);
        this.f['child_count'].setValidators([Validators.max(obj.apartment_type.max_childrens)]);
        this.f['adults_count'].updateValueAndValidity();
        this.f['child_count'].updateValueAndValidity();
        this.f['owner'].setValue(obj.owner ? obj.owner.id : null, {emitEvent: false});
        this.f['ownerName'].setValue(obj.owner ? (obj.owner.code + ' - ' + obj.owner.name) : '', {emitEvent: false});
        this.f['building'].setValue(obj.building ? obj.building.id : null, {emitEvent: false});
        this.service.search('inventory_master', {fields: ['owner_remaining_nights', 'guest_remaining_nights', 'monthly_free_nights'],
          filter: {apartment: {'_eq': obj.id}}}).then(res => {
          if (res.length) {
            this.f['owner_remaining_nights'].setValue(Number(res[0]['owner_remaining_nights']) + Number(res[0]['monthly_free_nights']));
            this.f['guest_remaining_nights'].setValue(res[0]['guest_remaining_nights']);
          }
        }).catch((error: any) => {
          this.service.handleError(error)
        });
      } else {
        this.f['adults_count'].clearValidators();
        this.f['adults_count'].setValidators([Validators.required]);
        this.f['adults_count'].updateValueAndValidity();
        this.f['child_count'].clearValidators();
        this.f['child_count'].updateValueAndValidity();
      }
    })
    this.f['building'].valueChanges.pipe(distinctUntilChanged()).subscribe((value: any) => {
      if (value) {
        this.service.search('apartment', {fields: ['*.*'], filter: {'building': {'_eq': value}}}).then(res => {
          this.listApartment = res.map((item: any) => {
            item['text'] = item.code;
            item['value'] = item.id;
            return item;
          });
          setTimeout(() => {
            this.f['apartment'].setValue(this.listApartment[0] ? this.listApartment[0].id : null);
          })
        })
      } else {
        this.listApartment = [];
      }
    });
    this.f['fromTo'].valueChanges.pipe(distinctUntilChanged()).subscribe((value: any) => {
      if (value && value.length === 2) {
        this.f['from_date'].setValue(moment(value[0]).utcOffset(0, true).set({s: 0, ms: 0}).toISOString(true));
        this.f['to_date'].setValue(moment(value[1]).utcOffset(0, true).set({s: 0, ms: 0}).toISOString(true));
        const count = duration(moment(value[1]).diff(moment(value[0]))).asDays();
        this.f['night_count'].setValue(count > 1 ? Math.round(count) : (count > 0 ? 1 : 0));
      }
    })
    this.getIdInfo();
  }

  ngAfterViewInit() {
    if (this.mode !== ItemMode.Add) {
      patchFormValue(this.formGroup, this.data);
      this.f['booking_date'].setValue(moment(this.data.booking_date).utc(false).toDate());
      this.f['birthday'].setValue(moment(this.data.birthday).utc(false).toDate());
      this.f['issue_date'].setValue(moment(this.data.issue_date).utc(false).toDate());
      this.f['expired_date'].setValue(moment(this.data.expired_date).utc(false).toDate());
      setTimeout(() => {
        this.f['fromTo'].setValue([new Date(this.nzModalData.data.from_date), new Date(this.nzModalData.data.to_date)], {emitEvent: false});
      })
      this.listDataShareGuest = this.data.guest_sharing;
      if (this.mode === ItemMode.View) {
        this.formGroup.disable({emitEvent: false});
      }
      if (this.mode === ItemMode.Edit) {
        this.f['apartment'].setValue(this.data.apartment.id);
        this.f['building'].setValue(this.data.building.id, {emitEvent: false});
        this.f['ownerName'].setValue(this.data.owner.code + ' - ' + this.data.owner.name, {emitEvent: false});
        if (this.data.status === BookingStatus.InHouse) {
          this.f['adults_count'].disable();
          this.f['child_count'].disable();
          this.f['fromTo'].disable();
          this.f['booking_date'].disable();
          this.f['apartment'].disable();
          this.f['building'].disable();
        }
      }
    }
    this.cdr.detectChanges();
  }

  get f() {
    return this.formGroup.controls;
  }

  editMode() {
    this.mode = ItemMode.Edit;
    patchFormValue(this.formGroup, this.nzModalData.data);
    this.f['birthday'].setValue(moment(this.data.birthday).utc(false).toDate());
    this.f['issue_date'].setValue(moment(this.data.issue_date).utc(false).toDate());
    this.f['expired_date'].setValue(moment(this.data.expired_date).utc(false).toDate());
    this.f['booking_date'].setValue(moment(this.data.booking_date).utc(false).toDate());
    this.formGroup.enable({emitEvent: false});
    this.f['ownerName'].disable();
    this.f['owner_remaining_nights'].disable();
    this.f['guest_remaining_nights'].disable();
    this.f['night_count'].disable();
    this.f['apartment'].setValue(this.data.apartment.id);
    this.f['building'].setValue(this.data.building.id, {emitEvent: false});
    this.f['ownerName'].setValue(this.data.owner.code + ' - ' + this.data.owner.name, {emitEvent: false});
    this.f['fromTo'].setValue([new Date(this.nzModalData.data.from_date), new Date(this.nzModalData.data.to_date)], {emitEvent: false});
    if (this.data.status === BookingStatus.InHouse) {
      this.f['adults_count'].disable();
      this.f['child_count'].disable();
      this.f['fromTo'].disable();
      this.f['booking_date'].disable();
      this.f['apartment'].disable();
      this.f['building'].disable();
    }
  }

  destroyModal() {
    this.modalRef.destroy()
  }

  handleFileUpload(key: string, obj: any) {
    this.f[key].setValue(obj);
    // this.listImageUpload[key] = obj.
  }

  edit(index: number) {
    this.scanMainGuest = false;
    const modalRef = this.modalService.create({
      nzContent: SharerInfoComponent,
      nzWidth: '40vw',
      nzClassName: 'modal-booking-sharer',
      nzData: {
        mode: ItemMode.Edit,
        data: this.listDataShareGuest[index],
        index: index + 1,
        lastGuest: true,
        listIdentityCode: [this.f['doc_no'].value].concat(this.listDataShareGuest.map(item => item.doc_no))
      },
    });
    modalRef.afterClose.subscribe((res: any) => {
      if (res) {
        this.scanMainGuest = !res.continue;
        Object.keys(this.listDataShareGuest[index]).forEach(key => {
          this.listDataShareGuest[index][key] = res.data[key];
        })
      } else {
        this.getIdInfo();
      }
    })
  }

  view(index: number) {
    const modalRef = this.modalService.create({
      nzContent: SharerInfoComponent,
      nzWidth: '40vw',
      nzClassName: 'modal-booking-sharer',
      nzData: {
        mode: ItemMode.View,
        data: this.listDataShareGuest[index],
        index: index + 1,
        lastGuest: true
      },
    });
    modalRef.afterClose.subscribe((res: any) => {
      if (res) {
        Object.keys(this.listDataShareGuest[index]).forEach(key => {
          this.listDataShareGuest[index][key] = res[key];
        })
        this.scanMainGuest = !res.continue;
      }
    })
  }

  delete(index: number) {
    const obj = this.listDataShareGuest[index];
    if (obj && obj.id) {
      obj['deleted'] = DeleteStatus.YES;
    } else {
      this.listDataShareGuest.splice(index, 1);
    }
  }

  add() {
    this.scanSubscription.unsubscribe();
    this.scanMainGuest = false;
    const modalRef = this.modalService.create({
      nzContent: SharerInfoComponent,
      nzWidth: '40vw',
      nzClassName: 'modal-booking-sharer',
      nzData: {
        mode: ItemMode.Add,
        index: this.listDataShareGuest.length + 1,
        lastGuest: this.listDataShareGuest.length === (this.f['adults_count'].value - 2),
        listIdentityCode: [this.f['doc_no'].value].concat(this.listDataShareGuest.map(item => item.doc_no))
      },
    });
    modalRef.afterClose.subscribe((res: any) => {
      if (res) {
        this.listDataShareGuest.push(res.data);
        this.scanMainGuest = !res.continue;
        if (res.continue) {
          this.add();
        } else {
          this.getIdInfo();
        }
      }
    })
  }

  onSave() {
    const validSharer = this.compareGuestShareAndAdult()
    const compareGuest = this.compareGuest()
    if (this.formGroup.valid && validSharer && compareGuest) {
      this.f['guest_sharing'].setValue(this.listDataShareGuest);
      const body = this.formGroup.getRawValue();
      this.service.getPublicCards(100, 0, body.apartment.replace('-', ''), CardStatus.ASSIGNED).subscribe((cards: any) => {
        if (cards && cards['CardCollection']['rows'].length > 0) {
          body.card_count = cards['CardCollection']['rows'].length;
        }
      })
      body.booking_date = moment(body.booking_date).format('YYYY-MM-DD');
      body.issue_date = moment(body.issue_date).format('YYYY-MM-DD');
      body.expired_date = moment(body.expired_date).format('YYYY-MM-DD');
      body.birthday = moment(body.birthday).format('YYYY-MM-DD');
      body.fromTo = undefined;
      body.ownerName = undefined;
      if (this.mode === ItemMode.Add) {
        body.id = undefined;
        body.guest_sharing = body.guest_sharing.map((item: any) => {
          item.id = undefined;
          body.issue_date = moment(body.issue_date).format('YYYY-MM-DD');
          body.expired_date = moment(body.expired_date).format('YYYY-MM-DD');
          body.birthday = moment(body.birthday).format('YYYY-MM-DD');
          return item;
        });
        this.service.createItem('booking/create', body).subscribe(() => {
          this.modalRef.destroy(true);
        }, (error: any) => {
         this.service.handleError(error)
        });
      } else if (this.mode === ItemMode.Edit) {
        body.guest_sharing = body.guest_sharing.map((item: any) => {
          item.id = item.id || undefined;
          body.issue_date = moment(body.issue_date).format('YYYY-MM-DD');
          body.expired_date = moment(body.expired_date).format('YYYY-MM-DD');
          body.birthday = moment(body.birthday).format('YYYY-MM-DD');
          return item;
        });
        this.service.updateItem('booking', body.id, body).subscribe(() => {
          this.modalRef.destroy(true);
        }, (error: any) => {
         this.service.handleError(error)
        });
      }
    } else {
      Object.values(this.formGroup.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  compareGuestShareAndAdult() {
    if (this.f['adults_count'].value - this.listDataShareGuest.length === 1) {
      return true;
    } else {
      this.noti.create('error', '', this.translateService.instant('error.sharer-required'));
      return false;
    }
  }

  compareGuest() {
    if ((this.f['adults_count'].value + this.f['child_count'].value - 1) < this.listDataShareGuest.length) {
      this.noti.create('error', '', this.translateService.instant('error.invalid-guest'));
      return false;
    }
    return true;
  }

  getIdInfo() {
    const listCheck = [this.f['doc_no'].value, this.listDataShareGuest.map(item => item.doc_no)]
    this.scanSubscription = this.identificationReaderService.getIdReadInfo().subscribe((idReadInfo: IdReadInfo) => {
      if (idReadInfo.id === IdReadInfoType.ID_INFO && this.scanMainGuest) {
        if (listCheck.indexOf(idReadInfo.data.idCode) === -1) {
          const textSplit = idReadInfo.data.qr.split('|');
          this.f['guest_name'].setValue(idReadInfo.data.personName || textSplit[3]);
          this.f['gender'].setValue((idReadInfo.data.gender || textSplit[4]) === 'Nam' ? Gender.Male : Gender.FeMale);
          this.f['birthday'].setValue(idReadInfo.data.dateOfBirth ?
            moment(idReadInfo.data.dateOfBirth, 'DD/MM/YYYY').utc(false).toDate() : moment(textSplit[3], 'DDMMYYYY').utc(false).toDate());
          this.f['doc_type'].setValue(DocType.Identification);
          this.f['doc_no'].setValue(idReadInfo.data.idCode || textSplit[0]);
          this.f['issue_date'].setValue(idReadInfo.data.issueDate ?
            moment(idReadInfo.data.issueDate, 'DD/MM/YYYY').utc(false).toDate() : moment(textSplit[6], 'DDMMYYYY').utc(false).toDate());
          this.f['expired_date'].setValue(moment(idReadInfo.data.expiryDate, 'DD/MM/YYYY').utc(false).toDate());
          this.f['address'].setValue(idReadInfo.data.residencePlace || textSplit[5]);
          this.f['issue_place'].setValue(this.translateService.instant('doc.default-place'));
          this.noti.success('', this.translateService.instant('booking.identify-read-success'));
        } else {
          this.noti.error('', this.translateService.instant('booking.identify-exist'));
        }
      }
    });
  }

  getMoreDataApartment(event: any) {
    if (!this.cancelSearchApartment) {
      const query = !!this.keywordApartment ? {
        '_or': [{'code': {'_icontains': this.keywordApartment}}],
        'status': {'_eq': MasterDataStatus.Active}, 'deleted': {'_eq': DeleteStatus.NO}
      } : {'status': {'_eq': MasterDataStatus.Active}, 'deleted': {'_eq': DeleteStatus.NO}}
      this.service.searchWithMeta('apartment', {
        filter: query,
        limit: 100,
        page: !event && !!this.keywordApartment ? 0 : Math.round(this.listApartment.length / 100),
      }).then((res: any) => {
        if (!event && !!this.keywordApartment) {
          this.listApartment = res.data.map((item: any) => {
            item['text'] = item.code;
            item['value'] = item.id;
            return item;
          });
        } else {
          this.listApartment = this.listApartment.concat(res.data.map((item: any) => {
            item['text'] = item.code;
            item['value'] = item.id;
            return item;
          }));
        }
        this.cancelSearchApartment = this.listApartment.length === Number(res.total);
      })
    }
  }

  filterDataApartment(event: any) {
    this.keywordApartment = event;
    this.cancelSearchApartment = false;
    if (event) {
      this.getMoreDataApartment(false);
    } else {
      this.listApartment = []
    }
  }
}
