export enum IdReadInfoType {
  ID_INFO = 2,
  ID_IMAGE = 4
}

export interface IdReadInfo {
  id: number;
  data: IdInfo;
}

export interface IdInfo {
  idCode: string;
  personName: string;
  dateOfBirth: string;
  gender: string;
  nationality: string;
  race: string;
  religion: string;
  originPlace: string;
  residencePlace: string;
  personalIdentification: string;
  issueDate: string;
  expiryDate: string;
  fatherName: string;
  motherName: string;
  wifeName: string;
  oldIdCode: string;
  qr: string;
}

export interface IdImage {
  imageData: string;
}

export interface ChipAuthen {
  isChipAuthem: 1 | 0;
}
