<form [formGroup]="formGroup">
  <div class="bg-white" nz-row [nzGutter]="[16, 16]">
    <div nz-col nzSpan="24">
      <app-control formControlName="night_count"
                   [controlConfig]="{title: 'booking.night_refund', type: 'number', max: nzModalData.night, required: true}"></app-control>
      <div class="text-red mt-3">{{'refundWarn' | translate: {night: nzModalData.night} }}</div>
    </div>
    <div nz-col nzSpan="24">
      <app-control formControlName="note" [controlConfig]="{title: 'booking.reason_refund', type: 'textarea'}"></app-control>
    </div>
  </div>
</form>
<div *nzModalTitle class="title-modal">{{ 'booking.title_refund' | translate }}</div>
<div *nzModalFooter>
    <button nz-button class="btn-cancel-modal" (click)="destroyModal()">{{ 'close' | translate }}</button>
    <button nz-button nzType="primary" (click)="submit()">{{ 'save' | translate }}</button>
</div>
