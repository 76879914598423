import {Component, OnInit} from '@angular/core';
import {
  BookingStatus, DeleteStatus,
  exportAsExcelFile,
  formatFilter, formatFilterBooking,
  ItemMode,
  ListStatus,
  MasterDataStatus,
  SharedService
} from '../shared/shared.service';
import {ProxyDynamicService} from '../shared/service/proxy-dynamic.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {forkJoin, map} from 'rxjs';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {TranslateService} from '@ngx-translate/core';
import {BookingItemComponent} from './booking-item/booking-item.component';
import moment from 'moment';
import {RefundModalComponent} from './refund-modal/refund-modal.component';
import {DatePipe} from '@angular/common';
import {environment} from '../../environments/environment';
import {duration} from 'moment/moment';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrl: './booking.component.scss'
})
export class BookingComponent implements OnInit {
  listStatus = [
    {text: 'status.booked', value: BookingStatus.Booked, class: 'booked'},
    {text: 'status.in-house', value: BookingStatus.InHouse, class: 'active'},
    {text: 'status.canceled', value: BookingStatus.Canceled, class: 'canceled'},
    {text: 'status.back-early', value: BookingStatus.BackEarly, class: 'inactive'},
    {text: 'status.finished', value: BookingStatus.Finished, class: 'finished'}
  ];
  tableConfig: any[] = [];
  filterConfig: any[] = [];
  filter: any = {
    limit: 15,
    page: 0,
    sort: '-booking_no',
    code: null,
    booking_date: null,
    apartment: null,
    operate: null,
    floor: null,
    building: null,
    project: null,
    status: []
  };
  source: any[] = [];
  listBuilding: any[] = [];
  listApartment: any[] = [];
  listOwner: any[] = [];
  totalRows: number = 0;

  constructor(
    private service: ProxyDynamicService,
    private modalService: NzModalService,
    private noti: NzNotificationService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private sharedService: SharedService,
  ) {
  }

  ngOnInit() {
    this.getMasterData();
  }

  getMasterData() {
    const listAPI = [
      this.service.search('apartment', {fields: ['*.*'], filter: {deleted: {'_eq': DeleteStatus.NO}}}),
      this.service.search('building', {filter: {deleted: {'_eq': DeleteStatus.NO}}}), this.service.search('owner', {filter: {deleted: {'_eq': DeleteStatus.NO}}})]
    forkJoin(listAPI).pipe(map(([listApartment, listBuilding, listOwner]) => ({
      listApartment: listApartment.map((item: any) => {
        item['text'] = item.code;
        item['value'] = item.id;
        return item;
      }),
      listBuilding: listBuilding.map((item: any) => {
        item['text'] = item.code + ' - ' + item.name;
        item['value'] = item.id;
        return item;
      }),
      listOwner: listOwner.map((item: any) => {
        item['text'] = item.name;
        item['value'] = item.id;
        return item;
      })
    }))).subscribe(e => {
      this.tableConfig = [
        {name: 'booking_no', title: 'booking.code', default: null, type: 'textAsLink', sortBy: 'ASC', width: '150px'},
        {name: 'booking_source', title: 'booking.date', default: null, type: 'text', sortBy: 'ASC', width: '150px', sortName: 'booking_date'},
        {name: 'apartment', title: 'apartment.label', default: null, type: 'object', sortBy: 'ASC', options: e.listApartment, width: '150px'},
        {name: 'card_count', title: 'booking.quantity', default: null, type: 'number', sortBy: 'ASC', width: '150px'},
        {name: 'guest_name', title: 'booking.main-guest', default: null, type: 'text', sortBy: 'ASC', width: '150px'},
        {name: 'adults_count', title: 'booking.adult', default: null, type: 'number', sortBy: 'ASC', width: '170px'},
        {name: 'child_count', title: 'booking.child', default: null, type: 'number', sortBy: 'ASC', width: '150px'},
        {name1: 'from_date', name2: 'to_date', title: 'booking.stay-date', default: null, type: 'date-range', sortBy: 'ASC', width: '250px', sortName: 'from_date'},
        {name: 'night_count', title: 'booking.night', default: null, type: 'number', sortBy: 'ASC', width: '170px'},
        {name: 'status', title: 'status.title', default: null, type: 'status', col: 12, options: this.listStatus, nzRight: true, required: true, sortBy: 'ASC', width: '150px'}
      ];
      this.filterConfig = [
        {name: 'booking_no', title: 'booking.code', default: '', type: 'text', col: 6},
        {name: 'booking_date', title: 'booking.date', default: null, type: 'date', col: 6},
        {name: 'apartment', title: 'apartment.label', default: null, type: 'dropdownServer', dbName: 'apartment', col: 6, options: e.listApartment},
        {name: 'guest_name', title: 'booking.main-guest', default: '', type: 'text', col: 6},
        {name: 'from_date', title: 'booking.arrival', default: '', type: 'date', col: 6},
        {name: 'to_date', title: 'booking.depart', default: '', type: 'date', col: 6},
        {name: 'status', title: 'status.title', default: [], type: 'status-multi-select', col: 6, options: this.listStatus},
      ];
      this.listApartment = e.listApartment;
      this.listBuilding = e.listBuilding;
      this.listOwner = e.listOwner;
      this.getData();
    });
  }

  getData(event?: any) {
    this.sharedService.showLoading(true);
    if (event) {
      Object.keys(event).forEach((key: any) => {
        this.filter[key] = event[key];
      })
    }
    this.service.searchWithMeta('booking', {
      fields: ['*.*', 'created_by.*', 'updated_by.*'],
      filter: formatFilterBooking(this.filter, this.filterConfig),
      "limit": this.filter.limit,
      "page": this.filter.page,
      'sort': [this.filter['sort']]
    }).then(res => {
      this.totalRows = res.total;
      this.source = res?.data?.map(item => {
        item['creator'] = item['created_by'] ? [item['created_by']['first_name'], item['created_by']['last_name']].join(' ') : undefined;
        item['updater'] = item['updated_by'] ? [item['updated_by']['first_name'], item['updated_by']['last_name']].join(' ') : undefined;
        item['booking_source'] = this.datePipe.transform(item['booking_date'], 'dd/MM/yyyy') + (item.source === 'KIOSK' ? ' - ' + 'KIOSK' : '');
        item['status'] = this.getFilterStatus(item);
        return item;
      });
      this.sharedService.showLoading(false);
    }).catch((error: any) => {
      this.service.handleError(error)
    });
  }

  getFilterStatus(item: any) {
    if (item.status !== BookingStatus.Canceled && item.status !== BookingStatus.BackEarly) {
      if (moment().isAfter(moment(item.to_date))) {
        return BookingStatus.Finished;
      } else if (moment().isBetween(moment(item.from_date), moment(item.to_date))) {
        return BookingStatus.InHouse;
      } else if (moment().isBefore(moment(item.from_date))) {
        return BookingStatus.Booked;
      }
    }
    return item.status;
  }

  onChangePage(event: any) {
    this.filter.page = event;
    this.getData();
  }

  onChangeSize(event: any) {
    this.filter.limit = event;
    this.getData();
  }

  edit($event: any) {
    const modalRef = this.modalService.create({
      nzContent: BookingItemComponent,
      nzWidth: '60vw',
      nzClassName: 'modal-booking',
      nzData: {
        mode: ItemMode.Edit,
        listApartment: this.listApartment.filter(r => r.status === MasterDataStatus.Active),
        listBuilding: this.listBuilding.filter(r => r.status === MasterDataStatus.Active),
        listStatus: this.listStatus,
        data: $event
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '', this.translate.instant('booking.updateSuccess'));
        this.getData();
      }
    });
  }

  delete($event: any) {
    this.service.delete('booking', $event.id).then(res => {
      this.noti.create('success', '', this.translate.instant('booking.deleteSuccess'));
      this.getData();
    }).catch((error: any) => {
      this.service.handleError(error)
    });
  }

  view($event: any) {
    const modalRef = this.modalService.create({
      nzContent: BookingItemComponent,
      nzWidth: '60vw',
      nzClassName: 'modal-booking',
      nzData: {
        mode: ItemMode.View,
        listApartment: this.listApartment.filter(r => r.status === MasterDataStatus.Active),
        listBuilding: this.listBuilding.filter(r => r.status === MasterDataStatus.Active),
        listStatus: this.listStatus,
        data: $event
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
          this.translate.instant('booking.updateSuccess'));
        this.getData();
      }
    });
  }

  add() {
    const modalRef = this.modalService.create({
      nzContent: BookingItemComponent,
      nzWidth: '60vw',
      nzClassName: 'modal-booking',
      nzData: {
        mode: ItemMode.Add,
        listApartment: this.listApartment.filter(r => r.status === MasterDataStatus.Active),
        listBuilding: this.listBuilding.filter(r => r.status === MasterDataStatus.Active),
        listStatus: this.listStatus
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
          this.translate.instant('booking.addSuccess'));
        this.getData();
      }
    });
  }

  onRefund($event: any) {
    let night = 0;
    if ($event.status === BookingStatus.Booked) {
      night = $event.night_count
    }
    if ($event.status === BookingStatus.InHouse) {
      night = moment($event.to_date).diff(moment(), 'days');
    }
    const modalRef = this.modalService.create({
      nzContent: RefundModalComponent,
      nzWidth: '400px',
      nzClassName: 'modal-booking',
      nzData: {
        data: $event,
        night: night
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.getData();
      }
    });
  }

  export() {
    this.service.search('booking', {
      fields: ['*', 'created_by.*', 'updated_by.*'],
      filter: formatFilterBooking(this.filter, this.filterConfig),
    }).then(dataResult => {
      let configColumn = this.tableConfig.map((item: any) => {
        return {
          headerText: item.title,
          field: item.name
        }
      })
      let listExport: any[] = [];
      if (dataResult?.length > 0) {
        dataResult.map((item: any) => {
          item['night_count'] = Math.round(duration(moment(item['to_date']).diff(moment(item['from_date']))).asDays());
          item['creator'] = item['created_by'] ? [item['created_by']['first_name'], item['created_by']['last_name']].join(' ') : undefined;
          item['updater'] = item['updated_by'] ? [item['updated_by']['first_name'], item['updated_by']['last_name']].join(' ') : undefined;
          item['booking_source'] = this.datePipe.transform(item['booking_date'], 'dd/MM/yyyy') + (item.source === 'KIOSK' ? ' - ' + 'KIOSK' : '');
          item['status'] = this.getFilterStatus(item);
          return item;
        }).forEach((data: any) => {
          let item: any = {};
          configColumn.forEach(e => {
            item[this.translate.instant(e.headerText)] = data[e.field] || '';
            if (e.field === 'date_created' || e.field === 'date_updated') {
              item[this.translate.instant(e.headerText)] = this.datePipe.transform(data[e.field], 'DD/MM/YYYY ');
            }
          })
          listExport.push(item);
        })
      } else {
        let item: any = {};
        configColumn.forEach(e => {
          item[this.translate.instant(e.headerText)] = '';
          listExport.push(item);
        })
      }
      exportAsExcelFile(listExport, this.translate.instant('booking.title') + '_' + this.datePipe.transform(new Date(), 'yyMMdd'));
    }, (error: any) => {
      this.service.handleError(error)
    }).catch((error: any) => {
      this.service.handleError(error)
    });
  }

  onSort(event: any) {
    this.tableConfig.forEach((col) => {
      if ((col['sortName'] && col['sortName'] === event) || (!col['sortName'] && col['name'] === event)) {
        this.filter['sort'] = col['sortBy'] === 'ASC' ? event : '-' + event;
        col['sortBy'] = col['sortBy'] === 'ASC' ? 'DESC' : 'ASC';
      } else {
        col['sortBy'] = 'ASC'
      }
    });
    this.getData();
  }
}
