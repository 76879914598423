<app-filter [title]="'booking.title'" [formConfig]="filterConfig" (onSearchChanges)="getData($event)"></app-filter>
<div class="m-3 pb-2 bg-white box-child">
  <div class="p-3">
    <app-button class="me-2" [iconLeft]="'plus'" [type]="'primary'" [label]="'add' | translate" (clickButton)="add()"></app-button>
    <app-button [iconLeft]="'file-down'" [label]="'export' | translate" (clickButton)="export()"></app-button>
  </div>
  <app-table-dynamic [columnsConfig]="tableConfig" [source]="source" (onEdit)="edit($event)" (onView)="view($event)" (onChangeSort)="onSort($event)"
                     [scroll]="{x: '100vw', y: 'calc(100vh - 480px)'}" (onRefund)="onRefund($event)" (onCancel)="onRefund($event)" [actionWidth]="'130px'"
                     [action]="{edit: false, delete: false, cancel: true, refund: true, billing: false, print: false, editBooking: true}" [total]="totalRows"
                     (onChangePage)="onChangePage($event)" (onChangeSize)="onChangeSize($event)" [page]="filter['page'] ? filter['page'] : 1"></app-table-dynamic>
</div>
